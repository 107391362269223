import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {Breadcrumb} from "gatsby-theme-jqi";
import ContactInfo from "./ContactInfo";
import AdditionalInfo from "./AdditionalInfo";
import "./PersonHero.scss";

const PersonHero: React.FC<JQI.PersonHeroProps> = ({
  name,
  personTitle,
  affiliations,
  roles,
  image,
  contactInfo,
  cv,
  researchProfile,
  breadcrumb,
}) => {
  const imageData = !!image.data ? getImage(image.data) : null;
  const affiliationString = affiliations?.join(" & ");

  return (
    <div className="person-hero">
      <Breadcrumb {...breadcrumb} />
      <div className="person-hero__inner">
        <h1 className="person-hero__name">{name}</h1>
        <p className="person-hero__titles">{personTitle}</p>
        <p className="person-hero__affiliation">{affiliationString}</p>
        {roles &&
          <div className="person-hero-roles__outer">
            {roles?.map((role, i) => {
                return (
                  <span className="site-search-result__page-type mr-2 person-hero__roles" key={i}>{role}</span>
                )
              })
            }
          </div>
        }
        {
          (!!image && !!imageData) &&
          <GatsbyImage className="person-hero__image" image={imageData} alt={image.alt || `Portrait of ${name}`} />
        }
        <div className="person-hero__info-sections">
          {
            !!contactInfo.length &&
            <div className="person-hero__info-section">
              <h2 className="person-hero__info-section-title">Contact Information</h2>
              {
                contactInfo.map((info, i) => <ContactInfo key={`contact-info-${i}`} {...info} />)
              }
            </div>
          }
          {
            (!!cv || !!researchProfile?.length) &&
            <div className="person-hero__info-section">
              <h2 className="person-hero__info-section-title">Additional Information</h2>
              <AdditionalInfo cv={cv} researchProfile={researchProfile} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default PersonHero;
