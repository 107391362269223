import React from "react";
import { navigate } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike, GatsbyImageProps } from "gatsby-plugin-image";
import classNames from "classnames";
import {useHover, BasicLink} from "gatsby-theme-jqi";
import "./CourseFeedItem.scss";

const CourseFeedItem: React.FC<JQI.CourseFeedItemProps> = ({
  date,
  title,
  fields,
  body,
  relationships,
  hideDescription,
  hideImage,
  hideDate,
}) => {
  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "article-feed-item": true,
    "article-feed-item--large": !hideDescription,
    "article-feed-item--small": hideDescription,
    hovered,
  });
  const imageData = relationships.hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const imageProps = {
    image: !!imageData ? getImage(imageData) : null,
    alt: relationships.hero?.relationships.heroImage?.mediaImage.alt || "",
  } as GatsbyImageProps;
  return (
    <article className={classList} onClick={() => { navigate(fields.pagePath) }} ref={hoverRef}>
      {
        (!hideImage && !!imageProps.image) &&
        <GatsbyImage className="article-feed-item__image" {...imageProps} />
      }
      <div className="article-feed-item__content">
        <h2 className="article-feed-item__title">
          {
            !!fields?.pagePath
              ? <BasicLink url={fields.pagePath}>{title}</BasicLink>
              : <span>{title}</span>
          }
        </h2>
        {
          !hideDate &&
          <div className="article-feed-item__date">{date}</div>
        }
        {
          (!!body?.summary && !hideDescription) &&
          <p className="article-feed-item__teaser" dangerouslySetInnerHTML={{ __html: body.summary }}></p>
        }
      </div>
    </article>
  );
};

export default CourseFeedItem;
