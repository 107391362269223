import React from "react";
import { PageProps, graphql } from "gatsby";
import { useSiteMetadata } from "gatsby-theme-jqi";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { HTMLHead, PageContentBody, PageContentMain, PageContentLayout, TextHTMLParagraph} from "gatsby-theme-jqi";
import EventHero from "../components/EventHero";
import { useNavHierarchies } from "gatsby-jqi-graphql-helpers";
import { getExternalGroups, getPublishedGroups, filterUnrelatedChildren } from "gatsby-theme-jqi";
import PostSidebarHeader from "../components/PostSidebarHeader";

const Event: React.FC<PageProps<JQI.EventData, JQI.EventContext>> = ({
  data,
  pageContext,
}) => {
  const { page } = data;
  const { defaultNodesByPath = {}, groupId } = pageContext;
  defaultNodesByPath[page.fields.pagePath] = { title: page.title };
  const { breadcrumb } = useNavHierarchies(page.fields.pagePath, defaultNodesByPath)
  const { eventType, hero, researchAreas, groups } = page.relationships;

  let externalGroups = groups ? getExternalGroups(groups, groupId) : [];
  externalGroups = filterUnrelatedChildren(externalGroups, groupId);
  externalGroups = getPublishedGroups(externalGroups);

  const eventHeroProps = {
    title: page.title,
    speaker: {
      name: page.speakerName,
      institution: page.speakerInstitution,
    },
    eventType: eventType.name,    
    researchAreas: researchAreas?.length ? researchAreas.map(({ name }) => name) : null,
    groups: externalGroups,
    startDatetime: page.startDatetime,
    endDatetime: page.endDatetime,
    location: page.location,
    link: page.link,
    breadcrumb,
    hero: hero
  } as JQI.EventHeroProps;

  const siteMetadata = useSiteMetadata();
  // Extract imageUrl for use in og:image
  const metaImageData = hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const image = !!metaImageData ? getImage(metaImageData): null;
  const imageUrl = siteMetadata.siteUrl + image?.images.fallback?.src;

  // Extract description for use in meta
  const meta = page.metatag?.filter((tag) => tag.attributes.name === "description").map((tag) => (
    {
      description: tag.attributes.content,
    }
  )).pop();

  return (
    <>
      <HTMLHead
        title={page.title}
        description={page.body?.summary || meta?.description}
        path={page.fields.pagePath}
        openGraph={{
          article: {
            publishedTime: page.created,
            modifiedTime: page.changed,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentMain>
        <PostSidebarHeader uri="/events" title="Events"/>
          <EventHero {...eventHeroProps} />
          <PageContentBody>
            {
              !!page.body?.processed &&
              <TextHTMLParagraph className="page-body" text={page.body} />
            }
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
    </>
  );
};

export default Event;

export const query = graphql`
  query EventPage($id: String!) {
    page: nodeEvent(id: { eq: $id }) {
      ...Event
      meta: field_meta {
        description
      }
    }
  }
`;