import React, { useRef } from "react";
import { Link } from "gatsby";
import { useSpring, animated } from "react-spring";
import {useHeightTransition, DownChevronIcon} from "gatsby-theme-jqi";

const Dropdown: React.FC<JQI.SiteHeaderDropdownProps> = ({
  expanded,
  items,
  parentId,
  parentTitle,
  onToggleClick,
}) => {
  const dropdownLabel = `Expand child menu of ${parentTitle}` as string;
  const listRef = useRef<HTMLUListElement>(null);
  const height = useHeightTransition(expanded, listRef) as number;
  const dropdownStyles = useSpring({
    config: {
      mass: 1,
      tension: 200,
      friction: 25,
      clamp: false,
      precision: 0.01,
      velocity: 0,
      easing: (t) => t,
    },
    from: { height: 0 },
    to: { height: expanded ? height : 0 },
  });
  return (
    <>
      <button
        aria-haspopup="true"
        aria-expanded={expanded}
        aria-label={dropdownLabel}
        onClick={onToggleClick}
        className="site-header__nav-dropdown-toggle"
      >
        <span className="sr-only">{dropdownLabel}</span>
        <DownChevronIcon className="icon icon-down-chevron" aria-hidden="true" width="17" height="10" />
      </button>
      <animated.div className="site-header__nav-dropdown" style={dropdownStyles} aria-hidden={!expanded}>
        <ul ref={listRef}>
          {
            items.map((item, i) => (
              <li key={`site-header-dropdown-${parentId}-${i}`}>
                <Link to={item.url}>{item.title}</Link>
              </li>
            ))
          }
        </ul>
      </animated.div>
    </>
  );
};

export default Dropdown;