import React from "react";
import {Breadcrumb, SocialShare} from "gatsby-theme-jqi";
import classNames from "classnames";
import "./PageContentHeader.scss";

const PageContentHeader: React.FC<JQI.PageContentHeaderProps> = ({
  children,
  title,
  breadcrumb,
  publishDate,
  className
}) => {
  const classList = classNames({
    "page-content__header": true,
    "post__header": !breadcrumb,
    [`${className}`]: !!className,   
  });

  return (
  <div className={classList}>
    {!!breadcrumb && <Breadcrumb {...breadcrumb} /> }
    
    <div className="page-content__header_top">
      <h1 className="page-content__header-title">{title}</h1>
      {
        !!publishDate &&
        <span className="page-content__header-publish-date">{publishDate}</span>
      }
      {className=="article-header" && <SocialShare/> }      
    </div>
    {children}
  </div>
  );
}

export default PageContentHeader;