import React from "react";
import { Link } from "gatsby";
import {FacebookIcon, TwitterIcon, YoutubeIcon, LinkedinIcon} from "gatsby-theme-jqi";
import "./SiteFooter.scss";

const defaultProps = {
  logos: [
    {
      href: "https://jqi.umd.edu/",
      src: "/jqi-logo-white.svg",
      alt: "UMD Joint Quantum Institute logo",
      width: 343,
      height: 69,
    },
    {
      href: "https://www.umd.edu/",
      src: "/umd-logo-white.svg",
      alt: "University of Maryland logo",
      width: 353,
      height: 98.7,
    },
    {
      href: "https://www.nist.gov/",
      src: "/nist-logo-white.svg",
      alt: "National Institute of Standards and Technology logo",
      width: 252,
      height: 33,
    },
  ],
  contact: {
    address: {
      street: {
        lineOne: "Room 2207 Atlantic Bldg.",
        lineTwo: "University of Maryland",
      },
      city: "College Park",
      state: "MD",
      zip: "20742",
    },
    phone: "(301) 314-1908",
    fax: "(301) 314-0207",
    email: "jqi-info@umd.edu",
  },
  social: {
    facebook: "https://www.facebook.com/JQInews",
    twitter: "https://twitter.com/JQInews",
    youtube: "https://www.youtube.com/user/JQInews",
    linkedin: "https://www.linkedin.com/company/institute-for-robust-quantum-simulation-rqs",
  },
  legalMenuItems: [
    {
      url: "https://www.umd.edu/privacy-notice",
      title: "Privacy Policy",
    },
    {
      url: "https://www.umd.edu/web-accessibility",
      title: "Web Accessibility",
    },
  ],
  orgName: "Joint Quantum Institute",
} as JQI.SiteFooterProps;

const SiteFooter: React.FC<JQI.SiteFooterProps> = ({
  logos = defaultProps.logos,
  contact = defaultProps.contact,
  social = defaultProps.social,
  legalMenuItems = defaultProps.legalMenuItems,
  orgName = defaultProps.orgName,
  menuItems,
}) => {
  const { address, phone, fax, email } = contact;
  const { facebook, twitter, youtube, linkedin } = social;
  const phoneDigits = !!phone ? phone.replace(/[^0-9]/g, "") : "" as string;
  const formattedPhone = !!phoneDigits ? `(${phoneDigits.substring(0, 3)}) ${phoneDigits.substring(3, 6)}-${phoneDigits.substring(6)}` : "" as string;
  const faxDigits = !!fax ? fax.replace(/[^0-9]/g, "") : "" as string;
  const formattedFax = !!faxDigits ? `(${faxDigits.substring(0, 3)}) ${faxDigits.substring(3, 6)}-${faxDigits.substring(6)}` : "" as string;
  return (
    <footer className="site-footer">
      <div className="site-footer__main">
        {
          !!logos.length &&
          <div className="site-footer__logos">
            <ul className="site-footer__logos-list">
              {
                logos.map((logo, i) => (
                  <li className="site-footer__logos-item" key={`site-footer-logo-${i}`}>
                    <a
                      className="responsive-img"
                      style={{paddingTop: `${(logo.height / logo.width) * 100}%`}}
                      href={logo.href}
                      target="_blank"
                    >
                      <img src={logo.src} alt={logo.alt} loading="lazy" />
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
        }
        <nav className="site-footer__nav" aria-label="Footer">
          <ul>
            {
              menuItems.map((item, i) => (
                <li key={`site-footer-menu-item-${i}`}>
                  <Link to={item.url}>{item.title}</Link>
                </li>
              ))
            }
          </ul>
        </nav>
        <div className="site-footer__contact">
          <h2 className="site-footer__contact-title">Contact Us</h2>
          {
            !!address && (
              <>
                <div className="site-footer__contact-address">
                  <p>
                    {address.street.lineOne}
                    <br />
                    {
                      !!address.street.lineTwo && (
                        <>
                          {address.street.lineTwo}
                          <br />
                        </>
                      )
                    }
                    {address.city}, {address.state} {address.zip}
                  </p>
                </div>
              </>
            )
          }
          <div className="site-footer__contact-phone-email">
            <p>
              {
                !!phoneDigits && (
                  <>
                    <strong>Phone: </strong>
                    <a href={`tel:+1${phoneDigits}`}>{formattedPhone}</a>
                    <br />
                  </>
                )
              }
              {
                !!faxDigits && (
                  <>
                    <strong>Fax: </strong>
                    <a href={`tel:+1${faxDigits}`}>{formattedFax}</a>
                    <br />
                  </>
                )
              }
              {
                !!email && (
                  <>
                  <strong>Email: </strong><a href={`mailto:${email}`}>{email}</a>
                  </>
                )
              }
            </p>
          </div>
        </div>
        {
          (!!facebook || !!twitter || !!youtube || !!linkedin) &&
          <nav className="site-footer__social" aria-label="Social">
            <ul>
              {
                !!twitter &&
                <li>
                  <a href={twitter}>
                    <span className="sr-only">Twitter</span>
                    <TwitterIcon className="icon icon-twitter" aria-hidden="true" width="20" height="16" />
                  </a>
                </li>
              }
              {
                !!facebook &&
                <li>
                  <a href={facebook}>
                    <span className="sr-only">Facebook</span>
                    <FacebookIcon className="icon icon-facebook" aria-hidden="true" width="11" height="22" />
                  </a>
                </li>
              }
              {
                !!youtube &&
                <li>
                  <a href={youtube}>
                    <span className="sr-only">Youtube</span>
                    <YoutubeIcon className="icon icon-youtube" aria-hidden="true" width="22" height="15" />
                  </a>
                </li>
              }
              {
                !!linkedin &&
                <li>
                  <a href={linkedin}>
                    <span className="sr-only">LinkedIn</span>
                    <LinkedinIcon className="icon icon-linkedin" aria-hidden="true" width="25" height="20" />
                  </a>
                </li>
              }
            </ul>
          </nav>
        }
      </div>
      <div className="site-footer__legal">
        <span className="site-footer__copyright">© {(new Date()).getFullYear()} {orgName}</span>
        <nav className="site-footer__legal-nav" aria-label="Legal">
          <ul>
            {
              legalMenuItems.map((item, i) => (
                <li key={`site-footer-legal-menu-item-${i}`}>
                  <a href={item.url}>{item.title}</a>
                </li>
              ))
            }
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default SiteFooter;
