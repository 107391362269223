import React from "react";
import { Link } from "gatsby";
import {LeftChevronIcon} from "gatsby-theme-jqi";
import "./PostSidebarHeader.scss";

const PostSidebarHeader: React.FC<JQI.LinkProps> = ({
    uri,
    title
}) => {
  return (
    <div className="post-sidebar">
        <Link className="sidebar-nav__parent" to={uri}>
        <LeftChevronIcon className="icon icon-left-chevron" aria-hidden="true" height="13" width="8" />
        <span>{title}</span>
        </Link>
    </div>
  );
};

export default PostSidebarHeader;