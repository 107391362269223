import React, { useEffect } from "react";
import { Globals } from "react-spring";
import { BaseLayout, useReduceMotion } from "gatsby-theme-jqi";
import useHeaderMenu from "../hooks/use-header-menu";
import useFooterMenu from "../hooks/use-footer-menu";

const Provider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const headerProps = {
    logo: {
      src: "/nsf-rqs-logo.png", // Update this to whatever the logo is named in ./src/static/
      alt: "Institute for Robust Quantum Simulation (RQS)", // Update this to use the actual group name
      width: 285, // Set this to the actual width of the logo
      height: 81, // Set this to the actual height of the logo
    },
    menuItems: useHeaderMenu(),
  } as JQI.SiteHeaderProps;
  const footerProps = {
    logos: [{
      src: "/nsf-rqs-logo.png",
      alt: "NSF Quantum Leap Challenge Institute for Robust Quantum Simulation",
      width: 285,
      height: 81
    }],
    orgName: "Institute for Robust Quantum Simulation",
    menuItems: useFooterMenu(),
    contact: {
      email: "rqs@umd.edu"
    },
    social: {
      youtube: "https://www.youtube.com/channel/UCG63EU2UTVqPynK4iVvpMww",
      twitter: "https://twitter.com/RQuantumSim",
      linkedin: "https://www.linkedin.com/company/institute-for-robust-quantum-simulation-rqs",
    }
   } as JQI.SiteFooterProps;
  // Globally disables React Spring animations based on user's OS-level reduced motion preference.
  const prefersReducedMotion = useReduceMotion() as boolean;
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    })
  }, [prefersReducedMotion]);
  return (
    <>
      <BaseLayout headerProps={headerProps} footerProps={footerProps}>
        {children}
      </BaseLayout>
    </>
  );
};

export default Provider;
