import { useStaticQuery, graphql } from "gatsby";

const useFooterMenu = (): JQI.MenuItem[] => {
	const { allMenuItems, site } = useStaticQuery(
		graphql`
			query FooterMenu {
				site {
					siteMetadata {
						groupId
					}
				}
				allMenuItems(filter: { menu_name: { eq: "rqs-footer-menu" } }) {
					edges {
						node {
							id
							title
							url
						}
					}
				}
			}
		`,
	);

	const groupId = site.siteMetadata.groupId;

	return allMenuItems.edges.map((edge: any) => {
		edge.node.url = edge.node.url.replace(new RegExp(`/${groupId}`), "");
		return edge.node;
	});
};

export default useFooterMenu;
