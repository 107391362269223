import React from "react";
import {GroupFeed, GroupFeedItem, PersonFeed, PersonFeedItem} from "gatsby-theme-jqi";
import "./ArticleExperts.scss";
import { splitGroupsAndCenters } from "gatsby-theme-jqi";

const ArticleExperts: React.FC<JQI.ArticleExpertsProps> = ({
  people,
  groups,
}) => {
  const { centers, researchGroups } = splitGroupsAndCenters(groups);

  return (
    <section className="article-experts" aria-labelledby="article-experts-title">
      <h2 className="article-experts__title" id="article-experts-title">Experts</h2>
      {
        !!people.length &&
        <div className="article-experts__people">
          <h3 className="article-experts__subhead">People</h3>
          <PersonFeed>
            {
              people.map((person, i) => (
                <PersonFeedItem key={`person-${i}`} {...person} />
              ))
            }
          </PersonFeed>
        </div>
      }
      {
        !!researchGroups.length &&
        <div className="article-experts__groups">
          <h3 className="article-experts__subhead">Research Groups</h3>
          <GroupFeed>
            {
              researchGroups.map((group, i) => (
                <GroupFeedItem key={`group-${i}`} {...group} />
              ))
            }
          </GroupFeed>
        </div>
      }
      {
        !!centers.length &&
        <div className="article-experts__groups">
          <h3 className="article-experts__subhead">Affiliated Research Centers</h3>
          <GroupFeed>
            {
              centers.map((group, i) => (
                <GroupFeedItem key={`group-${i}`} {...group} />
              ))
            }
          </GroupFeed>
        </div>
      }
    </section>
  );
}

export default ArticleExperts;
