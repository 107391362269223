import React from "react";
import { PageProps, graphql } from "gatsby";
import _ from "lodash";
import {GroupFeed, GroupFeedItem, HTMLHead, PageContentBody, PageContentMain, PageSection, PersonFeed, PersonFeedItem, PublicationDetails, PageContentLayout, HeroParagraph } from "gatsby-theme-jqi";
import { useNavHierarchies } from "gatsby-jqi-graphql-helpers";
import { useSiteMetadata } from "gatsby-theme-jqi";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { getExternalGroups, getPublishedGroups, filterUnrelatedChildren, splitGroupsAndCenters, filterPeople } from "gatsby-theme-jqi";
import PageContentHeader from "../components/PageContentHeader";

const Publication: React.FC<PageProps<JQI.PublicationData, JQI.PublicationContext>> = ({
  data,
  pageContext,
}) => {
  const { page } = data;
  const { defaultNodesByPath = {}, groupId } = pageContext;
  defaultNodesByPath[page.fields.pagePath] = { title: page.title };
  const { hero, printDocument, people, groups } = page.relationships;

  let externalGroups = groups ? getExternalGroups(groups, groupId) : [];
  externalGroups = filterUnrelatedChildren(externalGroups, groupId);
  externalGroups = getPublishedGroups(externalGroups);
  const {centers, researchGroups } = splitGroupsAndCenters(externalGroups);

  const localPeople = people ? filterPeople(people, groupId) : [];
  const { breadcrumb } = useNavHierarchies(page.fields.pagePath, defaultNodesByPath);

  const siteMetadata = useSiteMetadata();
  // Extract imageUrl for use in og:image
  const metaImageData = hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const image = !!metaImageData ? getImage(metaImageData): null;
  const imageUrl = siteMetadata.siteUrl + image?.images.fallback?.src;

  // Extract description for use in meta
  const meta = page.metatag?.filter((tag) => tag.attributes.name === "description").map((tag) => (
    {
      description: tag.attributes.content,
    }
  )).pop();

  return (
    <>
      <HTMLHead
        title={page.title}
        description={page.abstract?.value || meta?.description}
        path={page.fields.pagePath}
        openGraph={{
          article: {
            publishedTime: page.created,
            modifiedTime: page.changed,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentMain>
          <PageContentHeader breadcrumb={breadcrumb} title={page.title} className="publication-header">
            {
              !!hero &&
              <HeroParagraph {...hero} />
            }
          </PageContentHeader>
          <PageContentBody>
            <PageSection className="publications-section">
              {
                !!page.abstract &&
                <>
                  <h2 className="publication-section-subhead"><span>Abstract</span></h2>
                  <div className="publications-abstract" dangerouslySetInnerHTML={{__html: page.abstract.processed}}></div>
                </>
              }
              <h2 className="publication-section-subhead">Publication Details</h2>
              <PublicationDetails {...page} />
              {
                !!printDocument?.length &&
                <>
                  <h2 className="publication-section-subhead">Download the Publication</h2>
                  <div className="publication-document">
                  {
                    printDocument.map((document, i) => (
                      <a className="link" key={`document-${i}`} href={process.env.GATSBY_DRUPAL_URL + document.relationships.mediaDocument.uri.url} download={true}>{document.name}</a>
                    ))
                  }
                  </div>
                </>
              }
            </PageSection>
            {
              (!!localPeople?.length || !!externalGroups?.length) &&
              <PageSection title="Contributors" className="my-0 ">
                {
                  !!localPeople?.length &&
                  <>
                    <h3 className="page-section__subhead">People</h3>
                    <PersonFeed>
                      {
                        localPeople.map((person: JQI.PersonFeedItemProps, i: number) => (
                          <PersonFeedItem key={`person-${i}`} {...person} />
                        ))
                      }
                    </PersonFeed>
                  </>
                }
                {
                    !!externalGroups?.length &&
                    <>
                      {
                        !!researchGroups?.length &&
                        <>
                          <h2 className="page-section__subhead ">Research Group{researchGroups.length > 1 ? "s" : ""}</h2>
                          <GroupFeed>
                            {
                              researchGroups.map((group: JQI.GroupFeedItemProps, i: number) => (
                                <GroupFeedItem key={`group-${i}`} {...group} />
                              ))
                            }
                          </GroupFeed>
                        </>
                      }
                      {
                        !!centers?.length &&
                        <>
                          <h2 className="page-section__subhead ">Affiliated Research Centers</h2>
                          <GroupFeed>
                            {
                              centers.map((group: JQI.GroupFeedItemProps, i: number) => (
                                <GroupFeedItem key={`group-${i}`} {...group} />
                              ))
                            }
                          </GroupFeed>
                        </>
                      }
                    </>
                  }
              </PageSection>
            }
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
    </>
  );
};

export default Publication;

export const query = graphql`
  query PublicationQuery($id: String!) {
    page: publication(id: { eq: $id }) {
      ...Publication
      meta: field_meta {
        description
      }
    }
  }
`;
