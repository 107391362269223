import React from "react";
import { kebabCase } from "lodash";
import {SearchApp} from "gatsby-theme-jqi";
import {
  useSemesters
} from "gatsby-jqi-graphql-helpers";

const isBrowser = typeof window !== "undefined";

const createDictionary = (arr: JQI.TaxonomyTerm[]): { [key: string]: string } => {
  return arr.reduce((acc, cur: JQI.TaxonomyTerm) => ({
    ...acc,
    [kebabCase(cur.name)]: cur.id,
  }), {});
};

const CoursesSearch: React.FC<JQI.ArticlesSearchProps> = ({
  args = [],
  hideSearch,
  groupId,
  queryArgs
}) => {
  const semesterOptions = useSemesters() as JQI.TaxonomyTerm[];
  const semesterFilter = {
    label: "SEMESTER",
    property: "filters.semester",
    options: semesterOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  // If filters were passed via the Drupal View's contextual filters,
  // set the initial filter values accordingly. Otherwise, parse filters
  // from URL hash if any are present.
  if (!!args.length) {
    const semesterFilterIds = semesterOptions.map(({ id }) => `${id}`);
    for (let i = 0; i < args.length; i++) {
      const arg = args[i];      
      if (semesterFilterIds.indexOf(arg) !== -1) {
        semesterFilter.value = arg;
      }
    }
  } else {
    const locationHash = isBrowser ? kebabCase(window.location.hash.slice(1)) : "";    
    const semesterIdsBySlug = createDictionary(semesterOptions);
    semesterFilter.value = semesterIdsBySlug[locationHash] || "";
  }
  
  return (
    <SearchApp
      indexName={`courses_index_${groupId}`}
      feedType="course"
      filters={[
        semesterFilter
      ]}
      queryArgs={queryArgs}
      hideSearch={hideSearch || !!args.length}
    />
  );
};

export default CoursesSearch;
