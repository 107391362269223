import React from "react";
import {SearchApp} from "gatsby-theme-jqi";

const isBrowser = typeof window !== "undefined";

const ArticlesSearch: React.FC<JQI.ArticlesSearchProps> = ({
  args = [],
  hideSearch,
  groupId,
  queryArgs,
}) => {
  return (
    <SearchApp
      indexName={`articles_index_${groupId}`}
      feedType="article"
      filters={[]}
      queryArgs={queryArgs}
      query = ""
      hideSearch={hideSearch || !!args.length}
    />
  );
};

export default ArticlesSearch;